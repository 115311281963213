import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import { StaticImage } from "gatsby-plugin-image";

import SEO from "../../components/seo";

const lunarnewyearlunch = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Lunar New Year Lunch"
          description="SWA has been organizing the Lunar New Year Luncheon for the Elderly since 1977. Singapore Petroleum Company (SPC) is the anchor sponsor of the event and has partnered with SWA"
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative pt-16 overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  {/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    Introducing
                  </span> */}
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    Lunar New Year Lunch
                  </span>
                </h1>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  SWA has been organizing the Lunar New Year Luncheon for the
                  Elderly since 1977. Singapore Petroleum Company (SPC) is the
                  anchor sponsor of the event and has partnered with SWA since
                  2006 to share this “Chun Jie” (春节) festivity with the old
                  folks and to bring cheers to our needy elderly friends. The
                  lunch was held usually after the 15th day of the first month
                  in the Lunar New Year. Er Ku was engaged to provide the
                  entertainment programme. About 300 old folks are invited. The
                  elders were treated to a sumptuous Halal &amp; Non-Halal
                  Chinese lunch. Each elderly received some red packets and a
                  goody bag. They had a wonderful time with the very innovative
                  and retro nostalgic acts and songs put together by the group
                  of entertaining artists arranged by Er Ku. SPC has pledged its
                  support to continue sponsoring this event. We had very
                  positive feedback from the elderly who attended the lunch and
                  also received thank you letters from the homes.
                  <br />
                  <br />
                  The Singapore Petroleum Company (SPC) partnered with us since
                  2006 as the main sponsor for the event to share the festivity
                  and bring cheer to our elderly friends. We are indeed grateful
                  that when SPC joined PetroChina International (Singapore) Pte
                  Ltd, (PCSG) one of the largest oil and gas companies in the
                  world in 2009, they continued to partner with us.
                </p>
              </div>
            </div>
          </div>
          <section className="mx-1 mt-10 text-xl font-light border-black text-swa-2">
            <div className="grid grid-cols-2 my-5 gap-x-1 gap-y-1 sm:grid-cols-3 sm:gap-x-1 lg:grid-cols-4 xl:gap-x-1">
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228294/newyearlunchPage/eezyhjj4rcrpgduunrua.jpg"
                  alt="Lunar New Year Lunch"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228355/newyearlunchPage/kaxyjtgocnnbzp9nm43p.jpg"
                  alt="Lunar New Year Lunch"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228410/newyearlunchPage/zuguv0et5paatamlbtl1.jpg"
                  alt="Lunar New Year Lunch"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636944618/newyearlunchPage/skz7v3zrlxcyczjul1ig.jpg"
                  alt="Lunar New Year Lunch"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 50%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637229160/newyearlunchPage/nirg1fts7r1ae8t5k0ic.jpg"
                  alt="Lunar New Year Lunch"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228512/newyearlunchPage/tajyiwf8vqpzvrjn5tqx.jpg"
                  alt="Lunar New Year Lunch"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228590/newyearlunchPage/gnpl0hkqdaogskgkuabu.jpg"
                  alt="Lunar New Year Lunch"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 50%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228664/newyearlunchPage/qratvzteswvhp6ld5qo2.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228735/newyearlunchPage/zppy5ruwh4p4gtiayxhs.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228801/newyearlunchPage/ugydbwdcwfiwrozxmeik.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228869/newyearlunchPage/nr0wjzs51wta0pajbtwd.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637227807/newyearlunchPage/aj0mnsqyhzlrshe94ist.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228956/newyearlunchPage/cmrvkehuntq6clhpqcyq.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637229021/newyearlunchPage/imusqp0edrbxfzgc347r.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637229077/newyearlunchPage/dkmywio3ylegfbmhuq2m.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637227713/newyearlunchPage/hnvt0iqzprl9rqbqer1f.jpg"
                  alt="Singapore Vision Care Programme"
                  aspectRatio={10 / 7}
                  objectPosition={"50% 0%"}
                />
              </div>
            </div>
          </section>

          <div className="px-4 py-12 mx-auto mb-12 border rounded-lg max-w-7xl sm:px-6 lg:py-12 lg:px-8 lg:flex lg:items-center lg:justify-between border-swa-2">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
              {/* <span className="block">Lunary New Year Lunch 2019</span> */}
              <span className="block text-swa-1">
                Lunar New Year Lunch 2019
              </span>
            </h2>
            <div className="flex mt-8 lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/events/lunar-new-year-lunch-for-the-elderly-2019"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                >
                  Read more...
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default lunarnewyearlunch;
